














































































import { defineComponent } from "@vue/composition-api";
import { Button, Icon, List, PullRefresh, Search, Sticky, Tabs, Tab, CheckboxGroup, Checkbox, SubmitBar, Dialog, Popup, Cell, CellGroup } from "vant";
import axios from "@/helpers/axios";
import RenewRecord from "./renew-record.vue";
import SitePicker from "@/components/picker/site-picker.vue";
import moment from "moment";
moment.locale("zh-cn");

interface PlanDetail {
  id: number;
  activeTime: number;
  lastActiveTime: number;
  expireTime: number;
  box2Id: number;
  qrCode: string;
  mark: string;
  siteName: string;
  dueDay: number;
  checked: boolean;
}

export default defineComponent({
  components: {
    [Sticky.name]: Sticky,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [Popup.name]: Popup,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    SitePicker,
    RenewRecord
  },
  data() {
    return {
      keywordRaw: "",
      keyword: "",
      page: -1,
      size: 10,
      list: [] as PlanDetail[],
      mode: 0,
      dueDay: 60,
      checked: [] as number[],
      renewMode: true,
      submitLoading: false,
      price: 2000,

      refreshing: false,
      finished: false,
      loading: false,
      empty: false,
      showSitePicker: false,
      siteId: 0,
      siteName: "全部",
      renewRecordPopup: {
        show: false,
        cabinetId: 0
        // show: true,
        // cabinetId: 683
      }
    };
  },
  computed: {
    checkAll(): boolean {
      return this.checked.length == this.list.length;
    }
  },
  watch: {
    mode() {
      if (this.mode <= 0) {
        this.onRefresh();
      }
    }
  },
  methods: {
    onPickSite(site: { id: number; name: string }) {
      this.siteId = site.id;
      this.siteName = site.name;
      this.onRefresh();
    },
    showRenewRecordPopup(cabinetId: number) {
      this.renewRecordPopup.cabinetId = cabinetId;
      this.renewRecordPopup.show = true;
    },
    search() {
      this.keyword = this.keywordRaw;
      this.onRefresh();
    },
    onRefresh() {
      this.page = -1;
      this.checked = [];
      window.scrollTo(0, 0);
      this.getData(true);
    },
    onLoad() {
      this.getData();
    },
    toggleEditMode() {
      this.renewMode = !this.renewMode;
    },
    toggleCheck(item: PlanDetail) {
      if (item.checked) {
        item.checked = false;
        const index = this.checked.indexOf(item.id);
        if (index > -1) {
          this.checked.splice(index, 1);
        }
      } else {
        item.checked = true;
        const index = this.checked.indexOf(item.id);
        if (index === -1) {
          this.checked.push(item.id);
        }
      }
    },
    toggleCheckAll() {
      const checked = [];
      for (const item of this.list) {
        if (this.checkAll) {
          item.checked = false;
        } else {
          item.checked = true;
          checked.push(item.id);
        }
      }
      this.checked = checked;
    },
    async submit() {
      if (this.renewMode) {
        this.renew();
      } else {
        this.unbind();
      }
    },
    async renew() {
      const confirm = await Dialog.confirm({
        message: `您确定要为这${this.checked.length}台设备续费吗?
系统将会从您的余额扣除${(this.price / 100) * this.checked.length}元`
      })
        .then(() => true)
        .catch(() => false);
      if (!confirm) return;
      this.submitLoading = true;
      const postData = { cabinetIds: this.checked.join(",") };
      const res = await axios
        .post("/api/manage/servicePlan/renewCabinet", postData)
        .then(() => true)
        .catch(res => res);
      this.submitLoading = false;
      if (res !== true) {
        const res2 = await Dialog.confirm({
          message: res.response.data.msg + "\n是否尝试微信支付?",
          confirmButtonText: "微信支付",
          confirmButtonColor: "green"
        })
          .then(() => true)
          .catch(() => false);
        if (res2) {
          const payData = await axios
            .post("/api/manage/servicePlan/renewCabinetByWxPay", postData)
            .then(res => res.data.data)
            .catch(() => false);
          if (!payData) {
            Dialog.alert({ message: "发起微信支付失败" });
            return;
          }
          this.$toMiniApp("/pages/pay/pay?payData=" + encodeURIComponent(JSON.stringify(payData)));
          Dialog.confirm({
            message: "请在新打开的页面完成支付",
            confirmButtonText: "已完成支付"
          }).then(() => {
            this.onRefresh();
          });
          return;
        }
        return;
      }
      Dialog.alert({ message: "续费成功" });
      this.onRefresh();
    },
    async unbind() {
      const confirm = await Dialog.confirm({
        message: "您确定要解绑这" + this.checked.length + "台设备吗?"
      })
        .then(() => true)
        .catch(() => false);
      if (!confirm) return;
      this.submitLoading = true;
      const res = await axios
        .post("/api/manage/cabinet/unbindBusinessMulti", { cabinetIds: this.checked.join(",") })
        .then(() => true)
        .catch(() => false);
      this.submitLoading = false;
      if (!res) return;
      Dialog.alert({ message: "解绑成功" });
      this.onRefresh();
    },
    async getData(isRefresh = false) {
      const data = await axios
        .post("/api/manage/servicePlan/getCabinetList", {
          dueDay: this.dueDay,
          keyword: this.keyword,
          mode: this.mode,
          siteId: this.siteId,
          page: this.page + 1,
          size: this.size
        })
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!data) return;
      this.list = isRefresh ? data.list : this.list.concat(data.list);
      this.price = data.price;

      this.loading = false;
      this.refreshing = false;
      this.finished = data.list.length < this.size;
      this.page++;

      if (this.mode === 0) {
        this.finished = true;
      }
    },
    formatTime(time: number) {
      return moment(time).format("lll");
    }
  }
});
